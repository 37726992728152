import React from 'react'
import Layout from '../../components/Layout'
import { SEO } from '../../components/seo'
import ClientReviews from '../../components/ClientReviews'
import brandDesign from '../../images/brand-design.webp'
import customizedDesign from '../../images/test-img.png'
import ourTeamOfTalentedDesigners from '../../images/our-team-of-talented-designers.webp'

const pStyles = {
  fontSize: "18px",
  lineHeight: "1.5",
  letterSpacing: ".5px"
}

const rowPadding = {
  padding: "150px 0"
}

export default function BrandDesign() {
  return (
    <Layout>
      <section id='web_design' className='container'>
        <div className='row gx-5 flex-row-reverse' style={rowPadding}>
          <div className='col-md-6'>
            <div className='ms-md-5 position-relative'>
                <img src={brandDesign} className='img-fluid position-relative w-100' alt='Choose Plan' style={{height: 320, filter: "dropShadow('10px 10px 10px #ababab')"}}></img>
            </div>
          </div>
          <div className='col-md-6'>
            <h1 className='display-5 mb-4'>Brand Design</h1>
            <div className='line-divider'></div>
            <p className='mt-4' style={pStyles}>
              Branding or brand design is not just about a name or a company logo. It is the core representation of your business. Our Graphic Design Team will work closely with you to fully understand how we can translate and design your brand that will perfectly reflect your business and the values it represents. 
            </p>
            <a className="btn btn-orange default-padding mt-4 shadow text-capitalize" href="/get-started" role="button">Get started</a>
          </div>
        </div>
      </section>   

      <section className='w-100 brand-design parallax text-dark'>
        <div className='container'>
          <div className='row gx-5 justify-content-center text-center' style={rowPadding}>
            <div className='col-12 col-md-10'>
              <p className='text-uppercase text-primary' style={{ fontFamily: "GraphikSuper", letterSpacing: "1px"}}>Brand Name</p>
              <h2 className='display-6 mb-4 text-capitalize'>Your Story and Unique Identity</h2>
              <div className='line-divider mx-auto my-3'></div>
              <p className='mt-4' style={pStyles}>
                Your brand design in itself tells a story about you and your business. Our experienced team of designers are all eager to work with you to create a brand identity that represents you and your business. From the choice of colors to appealing designs- all these we take into account to visually appeal to your target market and at the same time guarantee that what we create is truly unique for you.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className='container'>
        <div className='row flex-row-reverse gx-5' style={rowPadding}>
          <div className='col-md-6'>
            <div className='position-relative'>
              <img src={customizedDesign} className='img-fluid position-relative w-100' alt='Choose Plan' style={{height: 320, filter: "dropShadow('10px 10px 10px #ababab')"}}></img>
            </div>
          </div>
          <div className='col-md-6'>
            <h2 className='display-6 mb-4'>Customized Designs</h2>
            <div className='line-divider'></div>
            <p className='mt-4' style={pStyles}>
              Our customized designs will allow you to express your own brand, showcase your identity and personality and tailor elements that align with your values. Your website is your powerful communication platform that will allow you to connect with your customers and market your brand not just here in NZ but around the world. Having a customized website design gives you a competitive edge.
            </p>
          </div>
        </div>
      </section>

      <section className='w-100 abstract text-dark'>
        <div className='container'>
          <div className='row gx-5' style={rowPadding}>
            <div className='col-md-6'>
              <div className='position-relative'>
                  <img src={ourTeamOfTalentedDesigners} className='img-fluid position-relative w-100' alt='Choose Plan' style={{height: 320, filter: "dropShadow('10px 10px 10px #ababab')"}}></img>
              </div>
            </div>
            <div className='col-md-6'>
              <h2 className='display-6 mb-4'>Our Team of Talented Designers</h2>
              <div className='line-divider'></div>
              <p className='mt-4' style={pStyles}>
                Our Team of dedicated designers are highly focused in achieving your goals and providing you ongoing professional support and service. GoogolWeb appreciates diversity within its team and with diverse group of creative minds working together, this gives us variety of choices for brilliant ideas and designs that allows us to work across different sectors and industries within the digital world.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className='container'>
        <div className='row' style={rowPadding}>
          <h2 className='display-6 text-center mb-5'>GoogolWeb Advantage</h2>
          <div className="inline-scrollbox u-scroll-x home-integrations-scrollbox">

            <ul className='inline-scrollbox-wrapper'>
              <li className='home-integrations-item' style={{height: "248px" , padding: "35px 30px"}}>
                <h4 className='h5 mb-3 text-uppercase'>Competitive Pricing</h4>
                <p className='mt-3'>
                  Offering the most modern, innovative website at a very competitive rate- is the best offer there is!
                </p>
              </li>

              <li className='home-integrations-item' style={{height: "248px" , padding: "35px 30px"}}>
                <h4 className='h5 mb-3 text-uppercase'>Get it Done by the Experts</h4>
                <p className='mt-3'>
                  Our experienced designers and developers guarantee you will be completely satisfied with the end results.
                </p>
              </li>

              <li className='home-integrations-item' style={{height: "248px" , padding: "35px 30px"}}>
                <h4 className='h5 mb-3 text-uppercase'>You are Always In the Know</h4>
                <p className='mt-3'>
                  Our Team will work closely with you every step of the way to ensure you are always informed of the progress of your website development.
                </p>
              </li>

              <li className='home-integrations-item' style={{height: "248px" , padding: "35px 30px"}}>
                <h4 className='h5 mb-3 text-uppercase'>Transparency of Operations</h4>
                <p className='mt-3'>
                  We value open communication and transparency in all our project for we believe a good alliance is founded on trust and openness.
                </p>
              </li>

              <li className='home-integrations-item' style={{height: "248px" , padding: "35px 30px"}}>
                <h4 className='h5 mb-3 text-uppercase'>Timely Delivery</h4>
                <p className='mt-3'>
                  Before we go ahead with your website development, we ensure that we give you a clear and realistic timeline to complete your website. 
                </p>
              </li>

              <li className='home-integrations-item' style={{height: "248px" , padding: "35px 30px"}}>
                <h4 className='h5 mb-3 text-uppercase'>Customer Satisfaction Always</h4>
                <p className='mt-3'>
                  It is a great accomplishment for us when we make our clients happy and satisfied with their website. As our top priority-its is not about us but about YOUR complete satisfaction with our work.
                </p>
              </li>
             
            </ul>
          </div>
        </div>
      </section>

      <ClientReviews></ClientReviews>
    </Layout>
  )
}

export const Head = () => (
  <SEO title="Brand Design" />
)