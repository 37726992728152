import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'

const rowPadding = {
    padding: "150px 0"
}

export default function ClientReviews() {
    return (
        <section id='client_review' className='w-100 abstract text-dark' style={{ backgroundRepeat: "round" }}>
            <div className='container'>
                <div className='row gy-5 justify-content-center mt-0 text-center row-padding' style={rowPadding}>

                    <div className='col-12 mt-0'>
                        <h1 className='display-5'>What our customers are saying...</h1>
                    </div>

                    <div className='col-md-12 col-lg-4'>
                        <div className="card client-review shadow-lg">
                            <div className="card-body bg-white" style={{ padding: "35px 25px" }}>
                                <h5 className="card-title fw-bold m-0">Clare J.</h5>

                                <div className='d-flex justify-content-center text-primary py-3 fs-5'>
                                    <FontAwesomeIcon icon={faStar} className='px-1' />
                                    <FontAwesomeIcon icon={faStar} className='px-1' />
                                    <FontAwesomeIcon icon={faStar} className='px-1' />
                                    <FontAwesomeIcon icon={faStar} className='px-1' />
                                    <FontAwesomeIcon icon={faStar} className='px-1' />
                                </div>

                                <p className='lh-base'>
                                    “GoogolWeb has been very supportive from start to finish. 
                                    They listened to what we wanted to achieve and has been very
                                    patient throughout the process. The outcome of the website is 
                                    beautiful and just what we imagined it would be. If you are looking
                                    for fair and affordable price I highly recommend GoogolWeb.”
                                </p>
                                
                            </div>
                        </div>
                    </div>

                    <div className='col-md-12 col-lg-4'>
                        <div className="card client-review shadow-lg">
                            <div className="card-body bg-white" style={{ padding: '35px 25px' }}>
                                <h5 className="card-title display-6 fs-5 fw-bold m-0">Perla V.</h5>

                                <div className='d-flex justify-content-center text-primary py-3 fs-5'>
                                    <FontAwesomeIcon icon={faStar} className='px-1' />
                                    <FontAwesomeIcon icon={faStar} className='px-1' />
                                    <FontAwesomeIcon icon={faStar} className='px-1' />
                                    <FontAwesomeIcon icon={faStar} className='px-1' />
                                    <FontAwesomeIcon icon={faStar} className='px-1' />
                                </div>

                                <p className='lh-base'>
                                    “Thank you GoogolWeb for creating a beautiful website for The Mrs Gold!
                                    I am really pleased with the outcome of the website and I’m so glad I 
                                    trusted your creativity and expertise. I would highly recommend your service. 
                                    Five star from me.”
                                </p>
                                
                            </div>
                        </div>
                    </div>

                    <div className='col-md-12 col-lg-4'>
                        <div className="card client-review shadow-lg">
                            <div className="card-body bg-white" style={{ padding: '35px 25px' }}>

                                <h5 className="card-title display-6 fs-5 fw-bold m-0">Cherie J.</h5>

                                <div className='d-flex justify-content-center text-primary py-3 fs-5'>
                                    <FontAwesomeIcon icon={faStar} className='px-1' />
                                    <FontAwesomeIcon icon={faStar} className='px-1' />
                                    <FontAwesomeIcon icon={faStar} className='px-1' />
                                    <FontAwesomeIcon icon={faStar} className='px-1' />
                                    <FontAwesomeIcon icon={faStar} className='px-1' />
                                </div>

                                <p className='lh-base'>
                                    “The GoogolWeb team were so amazing to work with! I couldn’t speak more highly of there workmanship and professionalism. They listened to what I wanted and then went the extra mile to ensure my website was absolutely perfect, they also took the initiative to suggest ideas to me that I wouldn’t have thought of, they were all so very patient with me because I am a very fussy and indecisive person. I would only recommend them for creating a five star website. I am very happy & very thankful for their hard work.”
                                </p>
                                
                            </div>
                        </div>
                    </div>

                    {/* <div className='col-lg-3 col-md-12 col-lg-4 px-md-3'>
                        <a href='/get-started' className="btn col-12 btn-primary fs-4 py-3 shadow">Get Started</a>
                    </div>
                    <div className='col-lg-3 col-md-12 col-lg-4 px-md-3'>
                        <a href='/contact-us' className="btn col-12 btn-orange fs-4 py-3 shadow">Contact Us</a>
                    </div> */}

                    <div className='container pb-5 mt-5'>
                        <div className="row justify-content-md-center" style={{ rowGap: "15px" }}>
                            <div className='col-lg-3 col-md-4 px-md-3'>
                                <a href='/get-started' className="btn col-12 btn-primary fs-5 py-3 shadow" type="button">Get Started</a>
                            </div>
                            <div className='col-lg-3 col-md-4 px-md-3'>
                                <a href='/contact-us' className="btn col-12 btn-orange fs-5 py-3 shadow" type="button">Contact Us</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}